<template>
  <validation-observer ref="richAssemblyRules">
    <b-form>
      <b-form-group label-for="div-container-rt">
        <span v-if="!readOnlyText">
          {{ textOne }}
        </span>
        <span
          v-if="!readOnlyText"
          class="text-danger"
        >
          *
        </span>
        <validation-provider
          #default="{ errors }"
          :name="textTwo"
          rules="required"
        >
          <div :id="!readOnlyText ? 'div-container-rt' : ''">
            <quill-editor
              :id="!readOnlyText ? 'editor-container' : ''"
              v-model="textAts"
              :options="editorOption"
              :disabled="readOnlyText"
              :state="errors.length > 0 ? false : null"
            >
              <div
                id="toolbar"
                slot="toolbar"
                :class="readOnlyText ? 'i-border-rt' : ''"
              >
                <template v-if="!readOnlyText">
                  <!-- options bold (B), italic (I), underline (U), strike (S), blockquote ('') -->
                  <button class="ql-bold i-options-rt-x" />
                  <button class="ql-italic i-options-rt-x" />
                  <button class="ql-underline i-options-rt-x" />
                  <button class="ql-strike i-options-rt-x" />
                  <button class="ql-blockquote i-options-rt-x" />
                  <!--/ options bold (B), italic (I), underline (U), strike (S), blockquote ('') -->

                  <!-- header values H1, H2 -->
                  <button
                    class="ql-header i-options-rt"
                    value="1"
                  />
                  <button
                    class="ql-header i-options-rt"
                    value="2"
                  />
                  <!--/ header values H1, H2 -->

                  <!-- list values (ordered, bullet) -->
                  <button
                    class="ql-list i-options-rt"
                    value="ordered"
                  />
                  <button
                    class="ql-list i-options-rt"
                    value="bullet"
                  />
                  <!--/ list values (ordered, bullet) -->

                  <!-- subscript and superscript (X2, x2) -->
                  <button
                    class="ql-script i-options-rt"
                    value="sub"
                  />
                  <button
                    class="ql-script i-options-rt"
                    value="super"
                  />
                  <!--/ subscript and superscript (X2, x2) -->

                  <!-- outdent/indent -->
                  <button
                    class="ql-indent i-options-rt"
                    value="-1"
                  />
                  <button
                    class="ql-indent i-options-rt"
                    value="+1"
                  />
                  <!--/ outdent/indent -->

                  <!-- text direction -->
                  <button
                    class="ql-direction i-options-rt"
                    value="rtl"
                  />
                  <!--/ text direction -->

                  <!-- font size dropdown -->
                  <select class="ql-size i-options-rt">
                    <option value="small" />
                    <!-- Note a missing, thus falsy value, is used to reset to default -->
                    <option selected />
                    <option value="large">
                      Largo
                    </option>
                    <option value="huge" />
                  </select>
                  <!--/ font size dropdown -->

                  <!-- font size heading -->
                  <select class="ql-header i-options-rt">
                    <option value="1" />
                    <option value="2" />
                    <option value="3" />
                    <option value="4" />
                    <option value="5" />
                    <option value="6" />
                    <!-- Note a missing, thus falsy value, is used to reset to default -->
                    <option selected />
                  </select>
                  <!--/ font size heading -->

                  <!-- font color -->
                  <select class="ql-color i-options-rt" />
                  <!--/ font color -->

                  <!-- font background -->
                  <select class="ql-background i-options-rt" />
                  <!--/ font background -->

                  <!-- font familiy dropdown -->
                  <select class="ql-font i-options-rt">
                    <option selected="selected" />
                    <option value="serif" />
                    <option value="monospace" />
                  </select>
                  <!--/ font familiy dropdown -->

                  <!-- font align -->
                  <select class="ql-align i-options-rt" />
                  <!--/ font align -->

                  <!-- remove formatting -->
                  <button class="ql-clean i-options-rt" />
                  <!--/ remove formatting-->

                  <!-- link -->
                  <button class="ql-link" />
                  <!--/ link-->
                </template>
              </div>
            </quill-editor>
          </div>
          <div
            v-if="!readOnlyText"
            class="mt-2"
          >
            <small class="text-danger">{{ errors[0] }}</small>
          </div>
        </validation-provider>
      </b-form-group>
    </b-form>
  </validation-observer>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },

  props: {
    textOne: {
      type: String,
      default: null,
    },
    textTwo: {
      type: String,
      default: null,
    },
    agreements: {
      type: String,
      default: null,
    },
    updatingData: {
      type: Boolean,
      default: false,
    },
    applyEditionX: {
      type: String,
      default: null,
    },
    readOnlyText: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      textAts: '',
      editorOption: {
        modules: {
          toolbar: '#toolbar',
        },
        theme: 'snow',
        placeholder: '. . . . .',
      },
      required, // validation
    }
  },

  watch: {
    updatingData(item) {
      if (item) {
        const nuevo = this.textAts.replace(/\s/g, '')

        if (nuevo === '<p></p>' || nuevo === '') {
          this.textAts = ''
        }

        this.$refs.richAssemblyRules.validate().then(success => {
          if (success) {
            this.$emit('assign-text-edit', this.textAts, true)
          } else {
            this.$emit('assign-text-edit', '', false)
          }
        })
      }
    },

    agreements(value) {
      this.textAts = value
    },
  },

  mounted() {
    // if (this.readOnlyText || this.applyEditionX == 'edit') {
    this.textAts = this.agreements
    // }
  },
}
</script>

<style scoped>
.i-options-rt {
  margin-right: 8px;
}

.i-options-rt-x {
  margin-right: 4px;
}

.i-border-rt {
  border-bottom: transparent;
  padding: 0;
}

#editor-container {
  height: 350px;
}

#div-container-rt {
  height: 395px;
}

@media (max-width: 768px) {
  #div-container-rt {
    height: 420px;
  }
}

@media (max-width: 576px) {
  #div-container-rt {
    height: 515px;
  }
}

@media (max-width: 320px) {
  #div-container-rt {
    height: 550px;
  }
}
</style>
